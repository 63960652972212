<template>
  <b-modal
    :id="'shareModal' + carDetail.ListingId"
    modal-class="share-modal"
    centered
    title="PashMotors"
    hide-footer
  >
    <div class="share-modal-header">
      <div class="share-modal-title">
        {{ carDetail.Year }} {{ carDetail.Make }} {{ carDetail.Model }}
      </div>
      <div class="share-modal-subtitle">
        <span v-if="carDetail.Trim"> {{ carDetail.Trim }} </span>
        <span v-else> [trim] </span>
      </div>
    </div>
    <div class="share-modal-body">
      <div class="share-via">{{$t("dashboard.label.ShareThisVia")}}</div>
      <ul class="share-icons">
        <li>
          <a href="#" @click.prevent="copyLink">
            <div class="icon">
              <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.0903 13.4099C11.5003 13.7999 11.5003 14.4399 11.0903 14.8299C10.7003 15.2199 10.0603 15.2199 9.67031 14.8299C7.72031 12.8799 7.72031 9.70995 9.67031 7.75995L13.2103 4.21995C15.1603 2.26995 18.3303 2.26995 20.2803 4.21995C22.2303 6.16995 22.2303 9.33995 20.2803 11.2899L18.7903 12.7799C18.8003 11.9599 18.6703 11.1399 18.3903 10.3599L18.8603 9.87995C20.0403 8.70995 20.0403 6.80995 18.8603 5.63995C17.6903 4.45995 15.7903 4.45995 14.6203 5.63995L11.0903 9.16995C9.91031 10.3399 9.91031 12.2399 11.0903 13.4099ZM13.9103 9.16995C14.3003 8.77995 14.9403 8.77995 15.3303 9.16995C17.2803 11.1199 17.2803 14.2899 15.3303 16.2399L11.7903 19.7799C9.84031 21.7299 6.67031 21.7299 4.72031 19.7799C2.77031 17.8299 2.77031 14.6599 4.72031 12.7099L6.21031 11.2199C6.20031 12.0399 6.33031 12.8599 6.61031 13.6499L6.14031 14.1199C4.96031 15.2899 4.96031 17.1899 6.14031 18.3599C7.31031 19.5399 9.21031 19.5399 10.3803 18.3599L13.9103 14.8299C15.0903 13.6599 15.0903 11.7599 13.9103 10.5899C13.5003 10.1999 13.5003 9.55995 13.9103 9.16995Z"
                    fill="#4B4B4B"
                />
              </svg>
            </div>
            <div class="icon-title">{{$t("dashboard.label.CopyLink")}}</div>
          </a>
        </li>
        <li>
          <a :href="`mailto:?subject=${carDetail.Year} ${carDetail.Make} ${carDetail.Model} ${$t('global.label.on')} PashMotors &amp;body=${shareText}`">
            <div class="icon">
              <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
                    fill="#4B4B4B"
                />
              </svg>
            </div>
            <div class="icon-title">{{$t("dashboard.label.email")}}</div></a
          >
        </li>
        <li>
          <a :href="`http://www.facebook.com/sharer.php?s=100&p[title]=Pash&p[summary]=YOUR_SUMMARY&p[url]=${link}&p[images][0]=${$store.getters.hostURL + carDetail.Image}`" target="_blank">
            <div class="icon">
              <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M12 2.04004C6.5 2.04004 2 6.53004 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85004C10.44 7.34004 11.93 5.96004 14.22 5.96004C15.31 5.96004 16.45 6.15004 16.45 6.15004V8.62004H15.19C13.95 8.62004 13.56 9.39004 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96C15.9164 21.5879 18.0622 20.3856 19.6099 18.5701C21.1576 16.7546 22.0054 14.4457 22 12.06C22 6.53004 17.5 2.04004 12 2.04004Z"
                    fill="#0165E1"
                />
              </svg>
            </div>
            <div class="icon-title">{{$t("dashboard.label.Facebook")}}</div></a
          >
        </li>
        <li>
          <a :href="`whatsapp://send?text=${shareText}`">
            <div class="icon">
              <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M12.5408 2C7.08078 2 2.63078 6.45 2.63078 11.91C2.63078 13.66 3.09078 15.36 3.95078 16.86L2.55078 22L7.80078 20.62C9.25078 21.41 10.8808 21.83 12.5408 21.83C18.0008 21.83 22.4508 17.38 22.4508 11.92C22.4508 9.27 21.4208 6.78 19.5508 4.91C17.6808 3.03 15.1908 2 12.5408 2ZM12.5508 3.67C14.7508 3.67 16.8108 4.53 18.3708 6.09C19.9208 7.65 20.7808 9.72 20.7808 11.92C20.7808 16.46 17.0808 20.15 12.5408 20.15C11.0608 20.15 9.61078 19.76 8.35078 19L8.05078 18.83L4.93078 19.65L5.76078 16.61L5.56078 16.29C4.74078 15 4.30078 13.47 4.30078 11.91C4.31078 7.37 8.00078 3.67 12.5508 3.67ZM9.03078 7.33C8.87078 7.33 8.60078 7.39 8.37078 7.64C8.15078 7.89 7.50078 8.5 7.50078 9.71C7.50078 10.93 8.39078 12.1 8.50078 12.27C8.64078 12.44 10.2608 14.94 12.7508 16C13.3408 16.27 13.8008 16.42 14.1608 16.53C14.7508 16.72 15.2908 16.69 15.7208 16.63C16.2008 16.56 17.1808 16.03 17.3908 15.45C17.6008 14.87 17.6008 14.38 17.5408 14.27C17.4708 14.17 17.3108 14.11 17.0608 14C16.8108 13.86 15.5908 13.26 15.3708 13.18C15.1408 13.1 15.0008 13.06 14.8108 13.3C14.6508 13.55 14.1708 14.11 14.0308 14.27C13.8808 14.44 13.7408 14.46 13.5008 14.34C13.2408 14.21 12.4408 13.95 11.5008 13.11C10.7608 12.45 10.2708 11.64 10.1208 11.39C10.0008 11.15 10.1108 11 10.2308 10.89C10.3408 10.78 10.5008 10.6 10.6008 10.45C10.7308 10.31 10.7708 10.2 10.8508 10.04C10.9308 9.87 10.8908 9.73 10.8308 9.61C10.7708 9.5 10.2708 8.26 10.0608 7.77C9.86078 7.29 9.66078 7.35 9.50078 7.34C9.36078 7.34 9.20078 7.33 9.03078 7.33Z"
                    fill="#4BB543"
                />
              </svg>
            </div>
            <div class="icon-title">{{$t("dashboard.label.WhatsApp")}}</div></a
          >
        </li>
      </ul>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['carDetail'],
  computed:{
    link() {
      return `${window.location.origin}/overview/${this.carDetail.ListingId}`;
    },
    shareText(){
      return this.$t(`Contact.shareEmailWithFriends`,[`${this.carDetail.Year} ${this.carDetail.Make} ${this.carDetail.Model}`,this.link])
    }

},
  methods: {
    async copyLink() {
      try {
        await navigator.clipboard.writeText(this.link);
        this.$store.dispatch("showToast", {
          content: this.$t('global.message.copyLinkSuccess'),
          type: "success"
        });
      } catch ($e) {
        this.$store.dispatch("showToast", {
          content: this.$t('global.message.copyLinkFailed'),
          type: "error"
        });
      }
    },

  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss">
.share-modal {
  .modal-header {
    position: relative;
    height: 40px;
    background: #006ac3;
    color: white;
    padding: 0 24px;
    display: flex;
    align-items: center;
    h5 {
      font-size: 20px;
      font-family: "Inter", sans-serif !important;
      font-weight: 600;
    }
    .close {
      padding: 0;
      position: absolute;
      top: 16px;
      font-weight: 300;
      opacity: 1;
      color: white;
      width: 30px;
      height: 100%;
    }
  }
  &-body {
    border-top: 1px solid #d0d0d0;
    padding: 24px;
  }
  &-header {
    padding: 24px;
  }
  ul {
    width: 300px;
    margin: auto;
    list-style: none;
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
  li {
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  .icon-title {
    font-size: 12px;
    color: #363636;
  }
  .share-via {
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;
  }
  &-title {
    font-size: 18px;
    color: #111111;
    font-weight: 500;
  }
  &-subtitle {
    font-size: 14px;
    color: #404040;
  }
  .modal-body {
    padding: 0;
  }
}
</style>
