<template>
  <div>
    <b-card v-if="listing" class="spec-box">
      <!-- {{listing}} -->
      <!-- <b-aspect aspect="3">
        <h5 class="textOnImage bg-light rounded p-3">
          More images coming soon
        </h5>
        <b-img
          :src="$store.getters.hostURL + cardData.Image"
          class="w-100"
          fluid-grow
          v-if="cardData.Image"
        ></b-img>
      </b-aspect> -->
      <!--  -->
      <b-col cols="12" class="p-0">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="blue"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless class="mt-8">
          <v-tab-item class="overview-tab">
            <b-row class="small">
              <b-col class="col-12 col-lg-6">
                <b-list-group class="list-group-flush">
                  <b-list-group-item>
                    <span>{{ $t("Overview.Mileage") }}:</span>
                    <strong>
                      {{ cardData.Kilometers }} {{ $t("Overview.KM") }}</strong
                    >
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.FuelType") }}:</span>
                    <strong>{{ cardData.FuelType }}</strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.Transmission") }}:</span>
                    <strong>{{ cardData.Transmission }}</strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.BodyType") }}:</span>
                    <strong>{{ cardData.BodyType }}</strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.Engine") }}:</span>
                    <strong>
                      {{ cardData.EngineCylinderCount }}
                      {{ $t("Overview.Cylinders") }} /
                      {{ cardData.Displacement }}
                      {{ $t("Overview.LiterSign") }}
                    </strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.Drivetrain") }}:</span>
                    <strong>{{ cardData.Drivetrain }}</strong>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col class="col-12 col-lg-6">
                <b-list-group class="list-group-flush">
                  <b-list-group-item>
                    <span>{{ $t("Overview.ExteriorColor") }}:</span>
                    <strong>
                      {{ exteriorColor }}
                    </strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.Seats") }}:</span>
                    <strong>{{
                      cardData.NumberOfSeats ? cardData.NumberOfSeats : 0
                    }}</strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.Doors") }}:</span>
                    <strong>{{
                      cardData.NumberOfPassengerDoors
                        ? cardData.NumberOfPassengerDoors
                        : 0
                    }}</strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.Keys") }}:</span>
                    <strong>{{numberOfKeys }}</strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.DriveSide") }}:</span>
                    <strong>{{ $t("Overview.Left") }}</strong>
                  </b-list-group-item>
                  <b-list-group-item>
                    <span>{{ $t("Overview.StockId") }}:</span>
                    <strong>{{ cardData.ListingRef }}</strong></b-list-group-item
                  >
                </b-list-group>
              </b-col>
            </b-row>
          </v-tab-item>
          <!--  -->
          <v-tab-item>
            <p class="selected-features-text">{{ $t("Overview.SelectedFeatures") }}</p>
            <div v-if="standardFeatures">
              <b-row>
                <b-col
                  cols="12"
                  lg="4"
                  v-for="(sft, key) in getSelectedFeatures()"
                  :key="key"
                  class="py-0"
                >
                  <!-- sft.TagValues[0].Installed && sft.IsRequired -->
                  <div
                    class="feature-item"
                    :class="{
                      'bg-whiteBlue': sft.IsPremium,
                      'bg-gray': !sft.IsPremium,
                    }"
                  >
                    <span> {{ sft.TagName }}</span>
                  </div>
                </b-col>
                <!--
                  <b-row
                    v-for="(sftc, key) in sf.TagCategories"
                    :key="key"
                    class="px-3 w-100"
                  >
                    <b-col
                      cols="4"
                      v-for="(sftct, key) in sftc.Tags"
                      :key="key"
                      v-show="sftct.TagValues[0].Installed"
                      class="px-3 pb-4"
                    >
                      &lt;!&ndash; <h6>{{ sftc.Name }}</h6> &ndash;&gt;
                        <div class="p-1 rounded" :class="{ 'bg-whiteBlue': sftct.IsPremium,'bg-gray': !sftct.IsPremium }">
                          <b-icon icon="check" class="mr-2" />
                          {{ sftct.TagName }}
                        </div>
                    </b-col>
                  </b-row>
-->
              </b-row>
              <b-button
                id="show-btn"
                @click="$bvModal.show('features-modal')"
                class="see-all"
                v-b-modal.modal-lg
                v-b-modal.modal-scrollable
              >
                <span v-if="!showMoreFeatureList">{{
                  $t("Overview.SeeAllVehicleFeatures")
                }}</span></b-button
              >

            </div>
            <div v-else>
              <b-row>
                <b-col
                  cols="12"
                  lg="4"
                  class="px-3 pb-1"
                  v-for="(skeleton, key) in renderSkeleton"
                  :key="key + skeleton"
                >
                  <b-skeleton width="100%" height="32px" />
                </b-col>
              </b-row>
            </div>
          </v-tab-item>
          <!--  -->
          <v-tab-item>
            <b-card
              class="bg-lgreen inspection-card"
              v-if="listing.InspectionStatus === 'Done'"
            >
              <!-- <h3 class="inspection-card-heading">
                {{ $t("Overview.PashCertified") }}
              </h3> -->
              <p class="inspection-card-desc">
                {{ $t("Overview.PashCertifiedDesc") }}
                <b-link>{{ $t("Overview.LearnMore") }}</b-link>
              </p>

              <b-button variant="outline-primary">
                {{ $t("Overview.ViewYourPashInspectionReport") }}
              </b-button>
            </b-card>
            <b-card
              class="bg-lblue inspection-card inspection-card-status-none"
              v-else-if="listing.InspectionStatus === 'None'"
            >
              <!-- <h3 class="inspection-card-heading">
                {{ $t("Overview.PashInspection") }}
              </h3> -->
              <h3 class="inspection-card-subheading">
                {{ $t("Overview.ForYourProtectionAndPeaceOfMind") }}
              </h3>
              <p class="inspection-card-desc">
                {{ $t("Overview.PashInspectionDesc")
                }}<b-link>{{ $t("Overview.LearnMore") }}</b-link>
              </p>
              <p class="inspection-card-link">
                <b-link>
                  {{ $t("Overview.PashInspectionReport") }}
                </b-link>
              </p>
            </b-card>
            <b-card
              class="bg-lorange inspection-card"
              v-else-if="listing.InspectionStatus === 'progress'"
            >
              <h3 class="inspection-card-heading">
                {{ $t("Overview.PashInspectionInProgress") }}
              </h3>
              <h3 class="inspection-card-subheading">
                {{ $t("Overview.ForYourProtectionAndPeaceOfMind") }}
              </h3>
              <p class="inspection-card-desc">
                {{ $t("Overview.ForYourProtectionAndPeaceOfMindDesc") }}
                <b-link>{{ $t("Overview.LearnMore") }}</b-link>
              </p>
              <p class="inspection-card-link">
                {{ $t("Overview.PashInspectionInProgressDesc") }}
                <b-link>{{ $t("Overview.LeaveYourEmailHere") }}</b-link>
              </p>
            </b-card>
          </v-tab-item>
          <!--  -->
          <v-tab-item>
            <div class="warranty">
              <img class="warranty-image" src="../../assets/img/warranty.svg" />
              <h5 class="warranty-title">{{ $t("Overview.Warranty") }}</h5>
              <p class="warranty-text">
                {{ $t("Overview.WarrantyText") }}
              </p>
              <b-link class="warranty-link">{{
                $t("Overview.LearnMore")
              }}</b-link>
            </div>
          </v-tab-item>
          <!--  -->
        </v-tabs-items>
      </b-col>
    </b-card>

    <b-card v-if="listing" class="spec-mobile-box">
      <!-- Overview -->
      <b-button
        v-b-toggle.collapse-1
        variant="primary"
        class="collapse-title"
        >{{ $t("Overview.Overview") }}</b-button
      >
      <b-collapse id="collapse-1" visible>
        <b-card class="collapse-text">
          <b-list-group class="list-group-flush">
            <b-list-group-item>
              <span>{{ $t("Overview.Mileage") }}:</span>
              <strong> {{ cardData.Kilometers }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.FuelType") }}:</span>
              <strong>{{ cardData.FuelType }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.Transmission") }}:</span>
              <strong>{{ cardData.Transmission }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.BodyType") }}:</span>
              <strong>{{ cardData.BodyType }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.Engine") }}:</span>
              <strong>
                {{ cardData.EngineCylinderCount }}
                {{ $t("Overview.Cylinders") }} / {{ cardData.Displacement }}
                {{ $t("Overview.LiterSign") }}
              </strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.Drivetrain") }}:</span>
              <strong>{{ cardData.Drivetrain }}</strong>
            </b-list-group-item>
          </b-list-group>
          <b-list-group class="list-group-flush">
            <b-list-group-item>
              <span>{{ $t("Overview.ExteriorColor") }}:</span>
              <strong>
                {{
                  cardData.ExteriorColor
                    ? cardData.ExteriorColor
                    : $t("Overview.Unknown")
                }}</strong
              >
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.Seats") }}:</span>
              <strong>{{
                cardData.NumberOfSeats ? cardData.NumberOfSeats : 0
              }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.Doors") }}:</span>
              <strong>{{
                cardData.NumberOfPassengerDoors
                  ? cardData.NumberOfPassengerDoors
                  : 0
              }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.Keys") }}:</span>
              <strong>{{
                cardData.NumberOfKeys ? cardData.NumberOfKeys : 0
              }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.DriveSide") }}:</span>
              <strong>{{ $t("Overview.Left") }}</strong>
            </b-list-group-item>
            <b-list-group-item>
              <span>{{ $t("Overview.StockId") }}:</span>
              <strong>{{ id }}</strong></b-list-group-item
            >
          </b-list-group>
        </b-card>
      </b-collapse>
      <!-- features -->
      <b-button
        v-b-toggle.collapse-2
        variant="primary"
        class="collapse-title"
        @click="getFeatures()"
        >{{ $t("Overview.Features") }}</b-button
      >
      <b-collapse id="collapse-2">
        <b-card class="collapse-text">
          <p class="selected-features-text">
            {{ $t("Overview.SelectedFeatures") }}
          </p>
          <div v-if="standardFeatures">
            <b-row>
              <b-col
                cols="12"
                lg="4"
                v-for="(sft, key) in getSelectedFeatures(9)"
                :key="key"
                class="py-0"
              >
                <!-- sft.TagValues[0].Installed && sft.IsRequired -->
                <div
                  class="feature-item"
                  :class="{
                    'bg-whiteBlue': sft.IsPremium,
                    'bg-gray': !sft.IsPremium,
                  }"
                >
                  <span> {{ sft.TagName }}</span>
                </div>
              </b-col>
            </b-row>
            <b-button
              id="show-btn"
              @click="$bvModal.show('features-modal')"
              class="see-all"
              v-b-modal.modal-lg
              v-b-modal.modal-scrollable
            >
              <span v-if="!showMoreFeatureList">{{
                $t("Overview.SeeAllVehicleFeatures")
              }}</span>
            </b-button>
          </div>
          <div v-else>
            <b-row>
              <b-col
                cols="12"
                lg="4"
                class="px-3 pb-1"
                v-for="(skeleton, key) in renderSkeleton"
                :key="key + skeleton"
              >
                <b-skeleton width="100%" height="32px" />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-collapse>
      <!-- inspection -->
      <b-button
        v-b-toggle.collapse-3
        variant="primary"
        class="collapse-title"
        >{{ $t("Overview.Inspection") }}</b-button
      >
      <b-collapse id="collapse-3">
        <b-card
          class="bg-lgreen inspection-card"
          v-if="listing.InspectionStatus === 'Done'"
        >
          <!-- <h3 class="inspection-card-heading">
                {{ $t("Overview.PashCertified") }}
              </h3> -->
          <p class="inspection-card-desc">
            {{ $t("Overview.PashCertifiedDesc") }}
            <b-link>{{ $t("Overview.LearnMore") }}</b-link>
          </p>

          <b-button variant="outline-primary">
            {{ $t("Overview.ViewYourPashInspectionReport") }}
          </b-button>
        </b-card>
        <b-card
          class="bg-lblue inspection-card inspection-card-status-none"
          v-else-if="listing.InspectionStatus === 'None'"
        >
          <!-- <h3 class="inspection-card-heading">
                {{ $t("Overview.PashInspection") }}
              </h3> -->
          <h3 class="inspection-card-subheading">
            {{ $t("Overview.ForYourProtectionAndPeaceOfMind") }}
          </h3>
          <p class="inspection-card-desc">
            {{ $t("Overview.PashInspectionDesc")
            }}<b-link>{{ $t("Overview.LearnMore") }}</b-link>
          </p>
          <p class="inspection-card-link">
            <b-link>
              {{ $t("Overview.PashInspectionReport") }}
            </b-link>
          </p>
        </b-card>
        <b-card
          class="bg-lorange inspection-card"
          v-else-if="listing.InspectionStatus === 'progress'"
        >
          <h3 class="inspection-card-heading">
            {{ $t("Overview.PashInspectionInProgress") }}
          </h3>
          <h3 class="inspection-card-subheading">
            {{ $t("Overview.ForYourProtectionAndPeaceOfMind") }}
          </h3>
          <p class="inspection-card-desc">
            {{ $t("Overview.ForYourProtectionAndPeaceOfMindDesc") }}
            <b-link>{{ $t("Overview.LearnMore") }}</b-link>
          </p>
          <p class="inspection-card-link">
            {{ $t("Overview.PashInspectionInProgressDesc") }}
            <b-link>{{ $t("Overview.LeaveYourEmailHere") }}</b-link>
          </p>
        </b-card>
      </b-collapse>
      <!-- warranty -->
      <b-button
        v-b-toggle.collapse-4
        variant="primary"
        class="collapse-title"
        >{{ $t("Overview.Warranty") }}</b-button
      >
      <b-collapse id="collapse-4">
        <b-card>
          <div class="warranty">
            <img class="warranty-image" src="../../assets/img/warranty.svg" />
            <h5 class="warranty-title">{{ $t("Overview.Warranty") }}</h5>
            <p class="warranty-text">
              {{ $t("Overview.WarrantyText") }}
            </p>
            <b-link class="warranty-link">{{
              $t("Overview.LearnMore")
            }}</b-link>
          </div>
        </b-card>
      </b-collapse>
    </b-card>
    <b-modal id="features-modal" size="lg" scrollable hide-footer>
      <template #modal-title>
        <h3 class="modal-title">{{ $t("Overview.AllFeatures") }}</h3></template
      >
      <div class="modal-carname">
        <h3 class="car-make">
          {{ cardData.Year }} {{ cardData.Make }} {{ cardData.Model }}
        </h3>
        <h3 class="car-mode">
          {{ cardData.Trim }}
        </h3>
      </div>
      <div class="modal-all-features">
        <div v-for="TagCategory in standardFeatures" :key="TagCategory.Name">
          <template v-if="TagCategory.Tags && TagCategory.Tags.length > 0 && TagCategory.Tags.some(o => o.TagValues[0] && o.TagValues[0].Installed === true)">
            <h4 class="pb-2 feature-part-title">
              {{ TagCategory.Name }}
            </h4>
            <ul class="px-0">
              <li
                  v-for="Tag in TagCategory.Tags.filter(
                            (item) =>
                              item.TagValues[0] && item.TagValues[0].Installed === true
                          )"
                  class="feature-item"
                  :key="Tag.TagName"
              >
                <span>{{ Tag.TagName }}</span>
              </li>
            </ul>
            <div
                class="px-0"
                v-if="
                          TagCategory.TagCategories && TagCategory.TagCategories.length > 0
                        "
            >
              <ul
                  v-for="TagCategory_lv1 in TagCategory.TagCategories"
                  class="feature-item"
                  :key="TagCategory_lv1.Name"
              >
                <li>
                  <span>{{ TagCategory_lv1.Name }}</span>
                  <ul
                      class="px-0"
                      v-if="TagCategory_lv1.Tags && TagCategory_lv1.Tags.length > 0"
                  >
                    <li
                        v-for="TagLv2 in TagCategory_lv1.Tags.filter(
                                  (item) =>
                                    item.TagValues[0] &&
                                    item.TagValues[0].Installed === true
                                )"
                        class="feature-item"
                        :key="TagLv2.TagName"
                    >
                      <span>{{ TagLv2.TagName }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import _range from "lodash/range";
export default {
  name: "OverViewDetail",
  props: {
    id: {
      required: true
    },
    listing: {
      required: true
    },
    carInfo: {
      required: true
    }
  },
  data() {
    return {
      index: 0,
      slide: 0,
      favorites: false,
      showMoreFeatureList: false,
      features: null,
      allOptions: null,
      questions: null,
      selected: null,
      showQ: null,
      showQ1: null,
      showQ2: null,
      tab: null,
      items: [
        this.$i18n.t("Overview.Overview"),
        this.$i18n.t("Overview.Features"),
        this.$i18n.t("Overview.Inspection"),
        this.$i18n.t("Overview.Warranty"),
      ],
    };
  },
  computed: {
    exteriorColor(){
      try{
        return this.carInfo.TagCategories.find(o => o.Id == 3).Tags.find(o => o.TagId == 8).TagValues.find(o => o.Installed == true).Value
      }catch (error){
        return null
      }
    },
    numberOfKeys(){
      try{
        return this.carInfo.TagCategories.find(o => o.Id == 3).Tags.find(o => o.TagId == 13).TagValues.find(o => o.Installed == true).Value
      }catch (error){
        return null
      }
    },
    standardFeatures(){
      return this.carInfo.TagCategories.find(o => o.Id === 4).TagCategories;
    },
    cardData() {
      if (this.$store.getters.listing) {
        return this.$store.getters.listing.Cardsdata;
      } else {
        return null;
      }
    },
    standardFeaturesList() {
      if (this.standardFeatures) {
        return this.standardFeatures.reduce(
          (featureList, feature) => [...featureList, ...feature.Tags],
          []
        );
      }
      return [];
    },
    renderSkeleton() {
      return _range(18);
    },
  },
  methods: {
    scrollPrice() {
      document
        .getElementById("priceDetails")
        .scrollIntoView({ behavior: "smooth" });
    },
    numberFormatter(value) {
      return parseFloat(value ? value : 0).toLocaleString(
        this.$store.getters.currencyCulture,
        {
          style: "unit",
          unit: "kilometer",
        }
      );
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat(this.$store.getters.currencyCulture, {
        style: "currency",
        currency: this.$store.getters.currency,
        minimumFractionDigits: value % 1 != 0 ? 2 : 0,
      }).format(value);
    },
    getSelectedFeatures(count = 18) {
      try {
        return this.standardFeaturesList
            .filter(
                (item) =>
                    (item.IsPremium || item.IsRequired) &&
                    item.TagValues[0] &&
                    item.TagValues[0].Installed === true
            )
            .slice(0, this.showMoreFeatureList ? undefined : count);
      } catch (e) {
        return [];
      }
    },
  },
  beforeDestroy() {
    //Clears the store
    //this.$store.dispatch("setListing", null);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/custom.scss";
.card {
  border-radius: 10px;
  border: none;
}
.card-body {
  padding: 24.5px !important;
}
.textOnImage {
  position: relative;
  top: 100px;
  left: 20px;
  width: 350px;
  opacity: 0.7;
  text-align: center;
}
.inspection-card {
  border-radius: 10px;
  @media (max-width: 575px) {
    margin: 16px;
  }
  &.inspection-card-status-none {
    background-color: #e6f0f9 !important;
    border: 1px solid #006ac3 !important;
    border-radius: 4px !important;
  }
  .card-body {
    padding: 40px !important;
    @media (max-width: 575px) {
      padding: 16px !important;
    }
  }
  &-heading {
    padding: 0.75rem 2rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    color: #1c1c1c;
    background-color: #a7c9f9;
    display: inline-block;
    border-radius: 40px;
    @media (max-width: 575px) {
      margin-bottom: 16px;
    }
  }
  &-subheading {
    font-size: 20px;
    line-height: 30px;
    color: #1c1c1c;
    margin-bottom: 24px;
    font-weight: 600;
    @media (max-width: 575px) {
      font-size: 24px;
      line-height: 40px;

      margin-bottom: 16px;
    }
  }
  &-desc {
    font-size: 18px;
    line-height: 26px;
    color: #1c1c1c;
    margin-bottom: 24px;
    a {
      color: #1c1c1c !important;
    }
  }
  &-link {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0 !important;
  }
}
.warranty {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &-image {
    width: 117px;
    height: 117px;
    margin-bottom: 24px;
  }
  &-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #1c1c1c;
    margin-bottom: 24px;
  }
  &-text {
    text-align: center;
    max-width: 300px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #404040;
  }
  &-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2277ef;
  }
}
</style>
<style lang="scss">
.spec-box {
  @media (max-width: 767px) {
    display: none !important;
  }
  .card-body {
    padding: 24.5px 40px !important;
    @media (max-width: 575px) {
      padding: 16px !important;
    }
  }
}
.overview-tab {
  .list-group-item {
    border: none;
    font-size: 16px;
    padding: 0;
    margin-bottom: 16px;
    @media (max-width: 991px) {
      display: flex;
      justify-content: space-between;
    }
    span {
      width: 150px;
      display: inline-block;
      @media (max-width: 991px) {
        width: auto;
      }
    }
  }

  strong {
    font-weight: 500;
  }
}
.bg-gray {
  background-color: #fafafa;
}
.card {
  border-radius: 0.25rem !important;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
}
.selected-features-text {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px !important;
  @media (max-width: 575px) {
    font-size: 14px;
    font-weight: bold;
  }
}
.feature-item {
  min-height: 36px;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  @media (max-width: 575px) {
    font-size: 14px;
    margin-bottom: 2px;
  }
}
.see-all {
  font-weight: 600 !important;
  border: none !important;
  border-bottom: 1px solid #006ac3 !important;
  padding-bottom: 8px !important;
  background: none !important;
  font-size: 20px !important;
  color: #006ac3 !important;
  margin-top: 32px !important;
  border-radius: 0 !important;
  @media (max-width: 575px) {
    font-size: 16px !important;
  }
}
#features-modal,
#features-modal-mobile {
  .modal-header {
    background: #006ac3 !important;
    height: 58px;
    position: relative;
  }
  .modal-body {
    padding: 0;
  }
  .modal-title {
    font-weight: 600;
    color: white;
    font-size: 20px;
    text-transform: capitalize;
  }
  .modal-content {
    border-radius: 4px;
    overflow: hidden;
    border: none;
  }
  .close {
    top: 15px;
    opacity: 1;
    color: white;
    padding: 0;
    font-size: 40px;
    position: absolute;
    right: 30px;
    height: 100%;
    padding-bottom: 10px;
    font-weight: normal;
  }
  .car-make {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 0;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
  .car-mode {
    font-weight: normal;
    font-size: 24px;
    color: #404040;
    margin-bottom: 0;
    line-height: normal;
  }
  .modal-carname {
    border-bottom: 1px solid #cdcdcd;
    padding: 24px;
    @media (max-width: 575px) {
      padding: 16px;
    }
  }
  .modal-all-features {
    padding: 20px 24px;
    @media (max-width: 575px) {
      padding: 16px 16px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      width: 50%;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
  li.feature-item {
    min-height: auto;
    margin-bottom: 16px;
    position: relative;
    padding: 0 16px 0 40px;
    font-size: 14px;
    line-height: normal;
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #232323;
      left: 23px;
    }
  }
}
.feature-part-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Inter", sans-serif !important;
  color: #111;
  @media (max-width: 575px) {
    font-size: 18px !important;
  }
}
.spec-mobile-box {
  margin-top: 16px;
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
    margin-top: 0 !important;
  }
  .card {
    border: none !important;
  }
  .card-body {
    padding: 16px !important;
  }
  .list-group-item {
    border: none !important;
    display: flex;
    padding: 0 0 16px 0;
    span,
    strong {
      display: block;
      width: 50%;
      font-size: 14px;
      font-weight: 500;
    }
    span {
      @media (max-width: 575px) {
        font-weight: 400;
      }
    }
  }
}
.spec-mobile-box > .card-body {
  padding: 0 !important;
}
.btn.collapse-title {
  color: #006ac3 !important;
  background: none !important;
  border: none !important;
  padding: 0 16px !important;
  font-weight: 600 !important;
  height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  width: 100%;
  justify-content: flex-start;
  border-radius: 0;
  position: relative;
  &:after {
    content: "\f078";
    font-family: "FontAwesome";
    font-weight: normal;
    margin-left: 16px;
    transition: 0.3s;
  }
  &.not-collapsed {
    &:after {
      transform: rotate(180deg);
    }
  }
}
.v-tabs {
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
}
.v-tab {
  font-size: 16px;
  color: #404040;
  &--active {
    font-weight: 600;
    color: #111111 !important;
  }
}
</style>
