<template>
  <div>
    <div class="slider-wrapper">
      <div class="left-side">
        <div class="side_cover"></div>
        <vueper-slides
          class="side_slide"
          ref="vehicleSlides_left"
          :touchable="false"
          :autoplay="false"
          :bullets="false"
          :arrows="false"
          fixedHeight="100%"
        >
          <vueper-slide v-for="(slide, i) in sliders6" :key="i" :image="slide.i_Link">
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="main-side">
        <vueper-slides
          ref="vehicleSlides"
          :touchable="false"
          :autoplay="false"
          :bullets="false"
          :arrowsOutside="true"
          :slide-ratio="9 / 16"
          @slide="
            $refs.vehicleSlides_left.goToSlide($event.currentSlide.index - 1, {
              emit: false,
            });
            $refs.vehicleSlides_right.goToSlide($event.currentSlide.index + 1, {
              emit: false,
            });
            $refs.vehicleSlides_2.goToSlide($event.currentSlide.index, {
              emit: false,
            });
          "
        >
          <vueper-slide v-for="(slide, i) in sliders6" :key="i" :image="slide.i_Link">
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="right-side">
        <div class="side_cover"></div>
        <vueper-slides
          class="side_slide"
          ref="vehicleSlides_right"
          :touchable="false"
          :autoplay="false"
          :bullets="false"
          :arrows="false"
          fixedHeight="100%"
        >
          <vueper-slide v-for="(slide, i) in sliders6" :key="i" :image="slide.i_Link">
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>
    <div class="col-12 col-sm-8 mx-auto thumb-wrapper">
      <vueper-slides
        class="no-shadow thumbnails mx-auto"
        ref="vehicleSlides_2"
        @slide="
          $refs.vehicleSlides.goToSlide($event.currentSlide.index, {
            emit: false,
          })
        "
        :visible-slides="5"
        :slide-ratio="1 / 1"
        :bullets="false"
        :touchable="false"
        :arrows="false"
      >
        <vueper-slide
          v-for="(slide, i) in sliders6"
          :key="i"
          :image="slide.i_Link"
          @click.native="
            $refs.vehicleSlides_2.goToSlide(i);
            $refs.vehicleSlides_left.goToSlide(i - 1);
            $refs.vehicleSlides_right.goToSlide(i + 1);
          "
        >
        </vueper-slide>
      </vueper-slides>
    </div>
    <!-- <div class="cornerBox">
      <b-alert :show="isReserved" variant="primary" class="p-1"
        ><h6 class="m-0">Reserved</h6>
      </b-alert>
      <b-alert :show="isConfirmed" variant="danger" class="p-1"
        ><h6 class="m-0">Sold</h6>
      </b-alert>
    </div> -->
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: ["slides", "isReserved", "isConfirmed"],
  data() {
    return {
      sliders6: [],
    };
  },
  methods: {
    slidersTo6() {
      this.sliders6 = this.slides;
      for (let i = 0; i <= 7 - this.slides.length; i++) {
        this.sliders6.push({ i_Link: require("@/assets/img/nophoto.jpg") });
      }
    },
  },
  beforeMount() {
    this.slidersTo6();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/custom.scss";

.cornerBox {
  position: absolute;
  text-align: center;
  width: 120px;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.thumbnails {
  margin-top: -38px;
  max-width: 345px;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 3px solid $white;
  transition: 0.3s ease-in-out;
  opacity: 1;
  cursor: pointer;
  border-radius: 100%;
  width: 60px !important;
  height: 60px;
  min-width: 60px;
  margin: 0 !important;
  @media (max-width: 991px) {
    border: none;
    border-radius: 2px;
    width: 80px !important;
    height: 54px !important;
    min-width: 54px !important;
  }
}
.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: $primary;
  @media (max-width: 991px) {
    border: 1px solid $primary;
  }
}

.side_cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(200, 200, 200, 0.5), rgba(200, 200, 200, 0.5));
  z-index: 1;
}
.side_slide {
  z-index: 0;
}
</style>

<style lang="scss">
@import "@/assets/sass/custom.scss";

.vueperslides,
.vueperslides__inner,
.vueperslides__parallax-wrapper {
  height: 100%;
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides__arrows--outside .vueperslides__arrow--prev {
  background-color: $primary;
  border-radius: 50px;
}
.vueperslides__arrows--outside .vueperslides__arrow--next {
  right: -9.3em;
}

.vueperslides__arrows--outside .vueperslides__arrow--prev {
  left: -9.3em;
}

.vueperslides__arrow svg {
  color: white;
  padding: 20px;
}

@media (max-width: 991px) {
  .vueperslides__arrows--outside .vueperslides__arrow--next {
    display: none !important;
  }

  .vueperslides__arrows--outside .vueperslides__arrow--prev {
    display: none !important;
  }
  .thumbnails {
    margin-top: 8px !important;
  }
}
.slider-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 488px;
}
.left-side {
  width: 220px;
  min-width: 220px;
  position: relative;
  border-radius: 4px 4px 0 0;
  height: 100%;
  overflow: hidden;
  margin-right: 8px;
}
.main-side {
  width: 100%;
  border-radius: 4px 4px 0 0;
  height: 100%;
}
.right-side {
  width: 220px;
  min-width: 220px;
  position: relative;
  border-radius: 4px 4px 0 0;
  height: 100%;
  overflow: hidden;
  margin-left: 8px;
}
.vueperslides__parallax-wrapper {
  padding-bottom: 0 !important;
}
@media (max-width: 991px) {
  .right-side,
  .left-side {
    display: none;
  }
  .thumbnails {
    max-width: 100% !important;
  }
  .card.slider-box {
    background: none !important;
    border: none !important;
    .card-body {
      padding: 0 !important;
    }
  }
  .main-side {
    border-radius: 4px !important;
    overflow: hidden;
  }
}
@media (max-width: 575px) {
  .slider-wrapper {
    height: 330px;
  }
  .thumb-wrapper {
    padding: 0 !important;
  }
}
</style>
